





































































































// Import vendors ----------------------------------------------------------------------------------
import { computed, watch, defineComponent, ref, toRef, onBeforeMount } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { from, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import axios from 'axios';
// Import utils ------------------------------------------------------------------------------------
import {
  useFormObserver,
  useFormFieldText,
  useFormFieldSelectChips,
  useFormFieldAutoComplete
} from '@/utils/forms.utils';
import { getCountries, useI18n } from '@/utils/i18n.utils';
import { useUnit } from '@/utils/unit.utils';
import { useAnalytics } from '@/utils/analytics.utils';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import AuthWrapper from '@/components/auth/AuthWrapper.vue';
import FormFieldText from '@/components/forms/FormFieldText.vue';
import FormFieldPhone from '@/components/forms/FormFieldPhone.vue';
import FormFieldSelectChips from '@/components/forms/FormFieldSelectChips.vue';
import FormFieldCheckbox from '@/components/forms/FormFieldCheckbox.vue';
import FormFieldSelect from '@/components/forms/FormFieldSelect.vue';
import FormFieldAutoComplete from '@/components/forms/FormFieldAutoComplete.vue';
// Import types ------------------------------------------------------------------------------------
import { EUnit } from '@/plugins/podocore/repositories/doctor.repository';
import { Professions } from '@/plugins/podocore/modules/doctor/doctor.module';
import type { TUnit } from '@/utils/unit.utils';
import type { ICountry } from '@digitsole/blackburn-iso-3166-dial';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthCreateProfile',
  components: {
    AuthWrapper,
    FormFieldText,
    FormFieldPhone,
    FormFieldSelectChips,
    FormFieldCheckbox,
    FormFieldSelect,
    FormFieldAutoComplete
  },
  props: {
    doctorService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const observer = useFormObserver();

    const { trackSuccess, trackFailure } = useAnalytics();
    const { navigatorLang } = useI18n();
    const { supportedUnits } = useUnit();

    const showLicenseHint = ref(false);

    const firstName = useFormFieldText<string>({
      key: 'firstName',
      value: '',
      rules: {
        required: true,
        min: 2,
        max: 50
      }
    });

    const lastName = useFormFieldText<string>({
      key: 'lastName',
      value: '',
      rules: {
        required: true,
        min: 2,
        max: 50
      }
    });

    const countryCode = useFormFieldAutoComplete<string, ICountry>({
      key: 'address.country',
      value: 'FR',
      items: getCountries(),
      rules: {}
    });

    const phoneDial = useFormFieldText<string>({
      key: 'phone.dial',
      value: '1',
      rules: {
        required: true
      }
    });

    const phoneValue = useFormFieldText<string>({
      key: 'phone.number',
      value: '',
      rules: {
        phone: toRef(phoneDial, 'value')
      }
    });

    const professions = useFormFieldSelectChips<Professions>({
      key: 'professions',
      value: [],
      items: Object.values(Professions),
      rules: {
        required: true,
        min: 2,
        max: 50
      }
    });

    // TODO : replace by correct component (select)
    const unit = useFormFieldAutoComplete<string, TUnit>({
      key: 'unit',
      value: EUnit.Metric,
      items: supportedUnits,
      rules: {
        required: true
      }
    });

    const licenseKey = useFormFieldText<string>({
      key: 'licenseKey',
      value: '',
      rules: {
        length: 64
      }
    });

    const acceptNewsletters = useFormFieldText<boolean>({
      key: 'acceptDpa',
      value: false,
      rules: {
        required: true
      }
    });

    function submit() {
      const data: any = {
        profile: {
          firstName: firstName.value,
          lastName: lastName.value,
          professions: professions.value
        },
        newsletters: {
          consented: acceptNewsletters.value
        },
        config: {
          lang: navigatorLang.value,
          unit: unit.value
        },
        countryCode: countryCode.value,
        licenseKey: licenseKey.value
      };

      if (phoneDial.value && phoneValue.value) {
        data.phone = `+${phoneDial.value}${phoneValue.value}`;
      }

      properties.doctorService.send({
        type: 'CREATE',
        data
      } as any);
    }

    watch(
      countryCode,
      (countryCode) => {
        const country = getCountries().find(({ alpha2 }) => alpha2 === countryCode.value);
        if (country?.dial) phoneDial.value = country.dial;
      },
      { immediate: true }
    );

    const isErrored = computed(() =>
      [{ creating: 'failure' }].some((element) => properties.doctorService.state.value.matches(element))
    );
    watch(isErrored, (value) => {
      if (value)
        trackFailure('User CreateDoctorProfile', properties.doctorService.state.value.context.creatingError);
    });

    const isDisabled = computed(() =>
      [{ creating: 'processing' }, 'challenged', 'success'].some((element) =>
        properties.doctorService.state.value.matches(element)
      )
    );

    const isLoading = computed(() =>
      [{ creating: 'processing' }].some((element) => properties.doctorService.state.value.matches(element))
    );

    watch(acceptNewsletters, (_acceptNewsletters) => {
      if (_acceptNewsletters.value) trackSuccess('User NewslettersAccepted', { value: true });
    });

    onBeforeMount(() => {
      from(axios.get(`${apiConfig.default}/tools/ip`))
        .pipe(first())
        .subscribe({
          next(response) {
            if (response?.data?.status === 'success') {
              countryCode.value = response.data.country_code2;
            } else {
              countryCode.value = 'FR';
            }
          },
          error() {
            // Ignore error
            countryCode.value = 'FR';
          }
        });
    });

    /* const subscription = properties.doctorService.service.value.subscribe((state) => {
      if (state.matches('success')) {
        root.$router.push({ name: 'auth--sign-in', params: { signedUp: 'true' } });
      }
    }); */

    // Reset service when leaving
    /* onBeforeUnmount(() => {
      properties.doctorService.send({
        type: 'RESET',
      });
      // subscription.unsubscribe();
    }); */

    return {
      // Form observer
      observer,
      // Form fields
      firstName,
      lastName,
      phoneDial,
      phoneValue,
      professions,
      unit,
      countryCode,
      licenseKey,
      acceptNewsletters,
      showLicenseHint,
      // Actions
      submit,
      // Flags
      isErrored,
      isDisabled,
      isLoading
    };
  }
});
